<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Salon Revenue Billing List <span class="badge badge-pill badge-secondary" style="font-size: 1.0em;">Total Price => {{metaData.totalPrice}} Ks</span>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row search-form">
                                    <div class="col-md-3 mt-1">
                                         <select class="form-control" v-model="filter.salonId" @change="selectSalon">
                                            <option value="">All Salon</option>
                                            <option :value="salon.id" v-for="salon in salonList">{{salon.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <select class="form-control" v-model="filter.saleId" v-bind:class="{ disabled: disabled }">
                                            <option value="">All Sale</option>
                                            <option :value="sale.id" v-for="sale in saleList">{{sale.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <select class="form-control" v-model="filter.reportStatus">
                                            <option value="">All Report Status</option>
                                            <option value="pending">Pending</option>
                                            <option value="recheck">Recheck</option>
                                            <option value="completed">Completed</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <select class="form-control" v-model="filter.orderStatus">
                                            <option value="">All Order Status</option>
                                            <option value="pending">Pending</option>
                                            <option value="confirm">Confirm</option>
                                            <option value="reject">Reject</option>
                                            <option value="completed">Completed</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input onfocus="(this.type='date')" placeholder="From Date" v-model="filter.fromDate" class="form-control" >
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input onfocus="(this.type='date')" placeholder="To Date" v-model="filter.toDate" class="form-control" >
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input onfocus="(this.type='date')" placeholder="Report Date" v-model="filter.reportDate" class="form-control" >
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input onfocus="(this.type='date')" placeholder="Billing Date" v-model="filter.billingDate" class="form-control" >
                                    </div>
                                </div>
                                <div class="row">    
                                     <div class="col-md-3 mt-1">
                                        <select class="form-control" v-model="filter.isRegister">
                                            <option value="">All Register Status</option>
                                            <option value="guest">Guest</option>
                                            <option value="user">User</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <select class="form-control" v-model="filter.discountType">
                                            <option value="">All Discount Status</option>
                                            <option value="package">Package Card</option>
                                            <option value="member">Member Card</option>
                                            <option value="coupon">Coupon</option>
                                            <option value="non">No Discount</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <button class="btn btn-primary mr-1 fbutton" @click="search()"><i class="fas fa-search"></i> Search</button>
                                        <button class="btn btn-info fbutton" @click="reset()"><i class="fas fa-sync"></i> Reset</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th v-for="(value, key) in columns"
                                                    v-bind:class="{ 'asc': filter.sort == key && filter.reverse == 'desc', 'desc': filter.sort == key && filter.reverse == 'asc' }">
                                                    <a href="#" @click="sortBy(key)" class="text-capitalize">
                                                        {{value}}
                                                    </a>
                                                </th>
                                                <th class="th-width-100">Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td class="customtootip">{{data.salon | subStr}} 
                                                    <span class="customtootiptext">{{data.salon}}</span>
                                                </td>
                                                <td>{{data.billingNo}}</td>
                                                <td>{{data.salePerson}}</td>
                                                <td>{{data.name}}</td>
                                                <td>{{data.price}}</td>
                                                <td>
                                                    <span class="badge badge-pill badge-primary" v-if="data.orderStatus == 'pending'">Pending</span>
                                                    <span class="badge badge-pill badge-info"    v-if="data.orderStatus == 'confirm'">Confirm</span>
                                                    <span class="badge badge-pill badge-warning" v-if="data.orderStatus == 'ontheway'">On The Way</span>
                                                    <span class="badge badge-pill badge-success" v-if="data.orderStatus == 'completed'">Completed</span>
                                                    <span class="badge badge-pill badge-danger"  v-if="data.orderStatus == 'reject'">Reject</span>
                                                </td>
                                                <td>
                                                    <span class="badge badge-pill badge-primary" v-if="data.report_status == 'pending'">Pending</span>
                                                    <span class="badge badge-pill badge-info"    v-if="data.report_status == 'recheck'">Confirm</span>
                                                    <span class="badge badge-pill badge-success" v-if="data.report_status == 'completed'">Completed</span>
                                                </td>
                                                <td>
                                                    <span class="badge badge-pill badge-info text-capitalize" >{{data.isRegister}}</span>
                                                </td>
                                                <td>
                                                    <span class="badge badge-pill badge-info text-capitalize" >{{data.discountType}}</span>
                                                </td>
                                                <td style="padding: 0px;">
                                                    <button class="btn btn-option text-primary"  v-b-modal.bv-modal-info @click="showDetail(data)"><i class="fas fa-sitemap"></i></button>
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0 ">
                                                <td colspan="10" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav v-if="dataList.length > 0 " class="table_info">
                                        Showing {{metaData.from}} to {{metaData.to}} of {{metaData.total}} entries
                                    </nav>
                                    <nav class="paging_simple_numbers">
                                        <ul class="pagination pagination-md">
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.prev_page_url === null }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page-1)">
                                                    &laquo;
                                                </a>
                                            </li>
                                            <li class="page-item" v-for="page in metaData.last_page" :key="page"
                                                :class="{ 'active':metaData.current_page === page }"
                                                v-if="Math.abs(page - metaData.current_page) < 3 || page == metaData.total - 1 || page == 0">
                                                <a href="#" @click.prevent="next(page)" class="page-link">
                                                    {{ page }}
                                                </a>
                                            </li>
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.current_page === metaData.last_page }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page+1)">
                                                    &raquo;
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-info" ref="my-modal" title="Revenue Detail" hide-footer>
            <table class="table table-bordered billing-table">
                <tbody>
                    <tr>
                        <th>Billing No</th>
                        <td  colspan="2">{{infoList.billingNo}}</td>
                    </tr>
                    <tr>
                        <th>Billing Date</th>
                        <td  colspan="2">{{infoList.billingDate}}</td>
                    </tr>
                    <tr>
                        <th>Total Price</th>
                        <td  colspan="2">{{infoList.price}}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-bordered billing-table" v-for="service in infoList.billingService">
                <tbody>
                    <tr>
                        <th>Service Name</th>
                        <td  colspan="2">{{service.serviceName}}</td>
                    </tr>
                    <tr>
                        <th>Stylist</th>
                        <td  colspan="2">{{service.stylist}}</td>
                    </tr>
                    <tr>
                        <th>Quantity</th>
                        <td  colspan="2">{{service.quantity}}</td>
                    </tr>
                    <tr>
                        <th>Origin Price</th>
                        <td  colspan="2">{{service.default_price}}</td>
                    </tr>
                    <tr>
                        <th>Discount Price</th>
                        <td  colspan="2">{{service.discount_price}}</td>
                    </tr>
                    <tr>
                        <th>Total Price</th>
                        <td  colspan="2">{{service.price}}</td>
                    </tr>
                </tbody>
            </table>

             <table class="table table-bordered billing-table" v-for="product in infoList.billingProduct">
                <tbody>
                    <tr>
                        <th>Product Name</th>
                        <td  colspan="2">{{product.productName}}</td>
                    </tr>
                    <tr>
                        <th>Quantity</th>
                        <td  colspan="2">{{product.quantity}}</td>
                    </tr>
                    <tr>
                        <th>Origin Price</th>
                        <td  colspan="2">{{product.default_price}}</td>
                    </tr>
                    <tr>
                        <th>Discount Price</th>
                        <td  colspan="2">{{product.discount_price}}</td>
                    </tr>
                    <tr>
                        <th>Total Price</th>
                        <td  colspan="2">{{product.price}}</td>
                    </tr>
                </tbody>
            </table>
        </b-modal>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'salonRevenueList',
        metaInfo: {
            title: "Salon Revenue List",
            titleTemplate: "%s ← Hlapa Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                disabled: true,
                alert: "",
                id: '',
                dataList: [],
                salonList: [],
                saleList: [],
                infoList: [],
                salonId: '',
                columns: {
                    salon_id: "salon",
                    billing_no: "Billing No",
                    sale_id: "Sale",
                    name: "name",
                    price: "Total Price",
                    order_status: "Order Status",
                    report_status: "Report Status",
                    is_register: "Register Type",
                    is_discount_type: "Discount Type"
                },
                filter: {
                    page: 1,
                    reverse: "asc",
                    sort: "created_at",
                    salonId: "",
                    saleId: "",
                    reportStatus: "",
                    orderStatus: "",
                    isRegister: "",
                    discountType: "",
                    fromDate: "",
                    toDate: "",
                    reportDate: "",
                    billingDate: ""
                },
                metaData: {
                    last_page: null,
                    current_page: 1,
                    from: null,
                    to: null,
                    total: null,
                    prev_page_url: null,
                    totalPrice: "",
                }
            }
        },
        watch: {
            '$route' (to, from) {
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                getSalonAction: 'getSalonAction',
                getSaleAction: 'getSaleAction',
                salonRevenueBillingAction: 'salonRevenueBillingAction'
            }),
            async getSalon(){
				await this.getSalonAction().then(res => {
					this.salonList = res.data.data
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
            async getSale(){
				let option = {
					salonId : this.salonId
				}
				await this.getSaleAction({
					...option
				}).then(res => {
					this.saleList = res.data.data
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
            async selectSalon(){
                this.filter.salonId ? this.disabled = false : this.disabled = true
				let option = {
					salonId : this.filter.salonId
				}
				await this.getSaleAction({
					...option
				}).then(res => {
					this.saleList = res.data.data
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
            sortBy(column) {
                this.filter.sort = column
                if (this.filter.sort == column) {
                    if (this.filter.reverse == "asc") {
                        this.filter.reverse = "desc"
                    } else if (this.filter.reverse == "desc") {
                        this.filter.reverse = "asc"
                    }
                } else {
                    this.filter.reverse = "asc"
                }
                this.fetchData()
            },
            async search() {
                this.filter.page = 1
                this.fetchData()
            },
            async fetchData() {
                this.isLoading = true
                let option = this.filter
                await this.salonRevenueBillingAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.metaData.last_page = res.data.meta.last_page
                        this.metaData.current_page = res.data.meta.current_page
                        this.metaData.prev_page_url = res.data.links.prev
                        this.metaData.from = res.data.meta.from
                        this.metaData.to = res.data.meta.to
                        this.metaData.total = res.data.meta.total
                        this.metaData.totalPrice = res.data.meta.totalPrice
                        this.$router.replace({ path: 'salon-revenue-billing', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, salonId: this.filter.salonId, 
                                            reportStatus:  this.filter.reportStatus, orderStatus:  this.filter.orderStatus, isRegister:  this.filter.isRegister, discountType:  this.filter.discountType,
                                            fromDate: this.filter.fromDate, toDate: this.filter.toDate, reportDate: this.filter.reportDate, billingDate: this.filter.billingDate} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            next: function (page) {
                this.filter.page = page
                this.fetchData();
            },
            reset(){
                this.$router.replace({ path: 'salon-revenue-billing', query: { page: 1, reverse: 'desc', sort: 'created_at', salonId: '', saleId: '', reportStatus: '', orderStatus: '', isRegister: '', discountType: '', fromDate: '', toDate: '',	reportDate: '',	billingDate: ''} }).catch(()=>{})
                this.$router.go()
            },
            showDetail(data) {
                this.infoList = data
            },
        },
        filters: {
            subStr: function(string) {
                let stLength = string.length
                if(stLength > 20){
                    return string.substring(0,20) + '...';
                }else{
                    return string
                }
            }
        },
        mounted() {
            this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.filter.salonId = this.$route.query.salonId
            this.filter.salonId = this.$route.query.salonId
            this.filter.reportStatus = this.$route.query.reportStatus
            this.filter.orderStatus = this.$route.query.orderStatus
            this.filter.isRegister = this.$route.query.isRegister
            this.filter.discountType = this.$route.query.discountType
            this.filter.fromDate = this.$route.query.fromDate
            this.filter.toDate = this.$route.query.toDate
            this.filter.reportDate = this.$route.query.reportDate
            this.filter.billingDate = this.$route.query.billingDate
            this.fetchData()
            this.getSalon()
            this.getSale()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
    .disabled {
      pointer-events:none;
      color: #bfcbd9;
      cursor: not-allowed;
      background-image: none;
      background-color: #eef1f6;
      border-color: #d1dbe5;   
    }
    .billing-table th{
        text-align: left;
        width: 200px;
    }
     .billing-table td{
        text-align: left;
    }
</style>